const AuthPlugin = {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    const token = localStorage.getItem("token");
    const email = token ? JSON.parse(atob(token.split(".")[1])).emails[0] : "";
    const isSupraUser = email.endsWith("@snecorp.com");

    Vue.prototype.$auth = {
      userName: email,
      isSupraUser: isSupraUser,
      scac_code: null,
      userType: null,
    };
  },
};

export default AuthPlugin;
