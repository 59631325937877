async function executeEventWithHandler(dispachEvent, detail, receiverEvent) {
  const infoEvent = {
    eventName: dispachEvent,
    detail: detail,
    receiverEvent: receiverEvent,
  };

  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    const eventListener = (e) => {
      resolve(e.detail); // Resuelve la promesa con los datos devueltos por el segundo microfrontend
      cleanup(); // Elimina el event listener después de resolver la promesa
    };

    const cleanup = () => {
      window.removeEventListener(receiverEvent, eventListener);
    };

    const eventInLogin = new CustomEvent("eventIntoLogin", {
      detail: infoEvent,
    });

    // Envía el evento al segundo microfrontend
    window.dispatchEvent(eventInLogin);

    window.addEventListener(receiverEvent, eventListener);
  });
}

const eventPlugin = {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    Vue.prototype.$executeEventWithHandler = executeEventWithHandler;
  },
};

export default eventPlugin;
