<template>
  <v-app>
    <!-- <AppNavBar /> -->
    <v-main class="grey lighten-4 overflow-x-hidden">
      <router-view />
    </v-main>
    <SnackBarService />
  </v-app>
</template>

<script>
// import AppNavBar from "./components/Header/AppNavBar/AppNavBar.vue";
import SnackBarService from "./components/Shared/SnackBarService.vue";

export default {
  components: {
    // AppNavBar,
    SnackBarService,
  },
  name: "App",

  data: () => ({
    //
  }),
  computed: {},
  created() {},
};
</script>
