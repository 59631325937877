import Vue from "vue";
import VueRouter from "vue-router";
import AuthPlugin from "../plugins/authPlugin";

Vue.use(AuthPlugin);
Vue.use(VueRouter);

const routes = [
  {
    path: "/yms-carrier-portal",
    redirect: { name: "inyardstorage" },
  },
  {
    path: "/yms-carrier-portal/checkinout",
    name: "checkinout",
    props: (route) => ({
      receiptid: route.params.receiptid,
      containerNumber: route.query.containerNumber,
      chassisNumber: route.query.chassisNumber,
      trailerNumber: route.query.trailerNumber,
      spot: route.query.spot,
      location: route.query.location,
      scacCode: route.query.scacCode,
    }),
    component: () =>
      import(
        "../views/CheckInCheckOutCarrierViews/CheckInCheckOutCarrierView.vue"
      ),
  },
  {
    path: "/yms-carrier-portal/receipt/:receipt_id",
    name: "receipt",
    component: () =>
      import("../views/CheckInCheckOutCarrierViews/NewReceiptView.vue"),
  },
  {
    path: "/yms-carrier-portal/yardstorage",
    name: "yardstorage",
    props: (route) => ({
      scac_code: route.query.scac_code,
      start_date: route.query.start_date,
      end_date: route.query.end_date,
      yard_location: route.query.yard_location,
    }),
    component: () => import("../views/YardStorageViews/YardStorageView.vue"),
  },
  {
    path: "/yms-carrier-portal/inyardstorage",
    name: "inyardstorage",
    props: (route) => ({
      scac_code: route.query.scac_code,
      start_date: route.query.start_date,
      end_date: route.query.end_date,
      yard_location: route.query.yard_location,
    }),
    component: () => import("../views/YardStorageViews/InYardStorageView.vue"),
  },
  {
    path: "/yms-carrier-portal/loader",
    name: "loader",
    component: () => import("../views/LoaderViews/LoaderView.vue"),
  },
  {
    path: "/yms-carrier-portal/containershistory",
    name: "containershistory",
    props: (route) => ({
      scac_code: route.query.scac_code,
      start_date: route.query.start_date,
      end_date: route.query.end_date,
    }),
    component: () =>
      import("../views/ContainersHistoryViews/ContainersHistoryView.vue"),
  },
  {
    path: "/yms-carrier-portal/drivers",
    name: "drivers",
    component: () => import("../views/DriversViews/DriversView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.name === "receipt") {
//     next();
//   } else if (to.path !== "/" && !Vue.prototype.$msal.isAuthenticated()) {
//     next("/");
//   } else {
//     next();
//   }
// });

export default router;
