import Vue from "vue";
import Vuex from "vuex";
// import CryptoJS from "crypto-js";
import VueCookies from "vue-cookies";

import applicationInsightsModule from "./applicationInsightsModule";

Vue.use(Vuex);
// Vue.use(VueCookies);

// Funciones para encriptar y desencriptar
// const ENCRYPTION_KEY = process.env.VUE_APP_ENCRIPTION_KEY; // Cambia esto a tu clave de encriptación

// function encryptData(data) {
//   return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
// }

function decryptData(encryptedData) {
  if (!encryptedData) return null;
  // const bytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
  // return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return encryptedData;
}

export default new Vuex.Store({
  state: {
    isAdmin: false,
    userData: decryptData(VueCookies.get("sessionCosmos")) || null,
  },
  getters: {
    isAdmin: (state) => state.isAdmin,
    userID: (state) => state.userData.id,
    userData: (state) => state.userData,
  },
  mutations: {
    setAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    setUserID(state, userID) {
      state.userID = userID;
    },
    // setUserData(state, userData) {
    //   const encryptedData = encryptData(userData);
    //   // localStorage.removeItem("userData");
    //   localStorage.setItem("userData", encryptedData);
    //   state.userData = userData;
    // },
    // deleteUserData(state) {
    //   localStorage.removeItem("userData");
    //   state.userData = {};
    // },
  },
  actions: {},
  modules: {
    applicationInsights: applicationInsightsModule,
  },
});
